import styles from './styles/home.module.css';
import React, {ChangeEvent, useState, useContext } from "react";
import axios from 'axios';
import env from '../environment/environment';
import {UserContext} from "../Provider/UserProvider";
import {ApplicationStateContext} from "../Provider/ApplicationStateProvider";

const Home = () => {
    const [input, setInput] = useState('');
    const {loginToken, user} = useContext(UserContext);
    const [output, setOutput] = useState('');
    const {setLoading} = useContext(ApplicationStateContext);
    const clickHandler = (event: React.MouseEvent) => {
        setLoading(true);
        axios({
            method: 'post',
            url: env.apiRoute + `/convert/${input}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
            data: {
                token: 'Basic ' + loginToken,
                userId: user?.user.id,
            },
        }).then((response) => {
            setOutput(response.data);
        }).finally(() => {
            setLoading(false);
        });
    }

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    }

    return <div className={styles.homeContainer}>
        <input type="text" value={input} onChange={changeHandler} className={styles.input}/>
        <button onClick={clickHandler} className={styles.button}>Umwandeln</button>
        <span className={styles.output}>{ output !== '' ? 'Ergebnis: ' : ''}{output}</span>
    </div>;
};
export default Home;
