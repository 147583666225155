import { useState } from 'react';
import environment from '../environment/environment';
const useUser = () => {
    const standartUserData = {"roles":[],"user":{"username":"testuser","roleIds":[],"id":"6426b737f5039aef991752c3"},"services":[]};
    const [user, setUser] = useState(environment.production === true ? undefined : standartUserData);
    const [loggedIn, setLoggedIn] = useState(environment.production === true ? false : false);
    const [loginToken, setLoginToken] = useState(environment.production === true ? undefined : 'dGVzdHVzZXI6cGFzc3dvcmQ=');
    return {user, setUser, loggedIn, setLoggedIn, loginToken, setLoginToken};
};
export default useUser;
