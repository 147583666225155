import React, {useState, useEffect, FormEvent, ChangeEvent} from "react";
import { useContext } from "react";
import { UserContext } from "../Provider/UserProvider";
import { Navigate } from "react-router-dom";
import styles from './styles/login.module.css';

const Login = () => {
    const [info, setInfo] = useState("");
    const { loggedIn, login } = useContext(UserContext);
    const [creds, setCreds] = useState({ username: "", password: "" });

    const usernameChanged = (event: ChangeEvent) => {
        setCreds((creds) => ({ ...creds, username: (event.target as HTMLInputElement).value }));
    };
    const passwordChanged = (event: ChangeEvent) => {
        setCreds((creds) => ({ ...creds, password: (event.target as HTMLInputElement).value }));
    };

    const submitHandler = (event: FormEvent) => {
        event.preventDefault();
        login(btoa(creds.username + ':' + creds.password)).then((worked) => {
            if (!worked)
                setInfo("Login fehlgeschlagen");
        });
    };

    useEffect(() => {
        if (localStorage.getItem('user') !== null)
            login(localStorage.getItem('user') as string).then(() => {});
    }, [loggedIn, login]);

    if (loggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <div className={styles.wrapper}>
            <form className={styles.form} onSubmit={submitHandler}>
                <h1>Login</h1>
                <input className={styles.input} type={"text"} placeholder="Nutzername" value={creds.username} onChange={usernameChanged} />
                <input className={styles.input} type={"password"} placeholder="Passwort" value={creds.password} onChange={passwordChanged} />
                <input className={styles.button} type={"submit"}/>
                <p>{info}</p>
            </form>
        </div>
    );
};
export default Login;
