import UserProvider from './UserProvider';
import { RouterProvider } from 'react-router-dom';
import LoginRouter from '../Router/Router';
import React from "react";
import {ApplicationStateProvider} from "./ApplicationStateProvider";
interface IProps {
    children?: React.ReactNode;
}
const ProviderComponent = ({children}: IProps) => {
    return (
        <ApplicationStateProvider>
            <UserProvider>
                <RouterProvider router={LoginRouter}/>
            </UserProvider>
        </ApplicationStateProvider>
       
    );
};
export default ProviderComponent;
