const env = process.env.NODE_ENV;
const environment: {
    production: boolean;
    apiRoute: string;
    apiServiceRoute: string;
} = {
    production: env === 'production',
    apiRoute: env === 'production' ? 'https://api.lreg0.de/namegen' : 'http://localhost:4000',
    apiServiceRoute: 'https://api.lreg0.de'
};
export default environment;
