import { Navigate, Outlet } from "react-router-dom"
import { useContext } from "react";
import { UserContext } from "../Provider/UserProvider";
const LoggedInGuard = () => {
    const {loggedIn} = useContext(UserContext);
    if (!loggedIn) {
       return <Navigate to="/login" />;
    }
    return (<Outlet/>);
};
export default LoggedInGuard;