import React from 'react';
import './App.css';
import ProviderComponent from "./Provider/ProviderComponent";

function App() {
  return (
      <ProviderComponent />
  );
}

export default App;
