import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
const Content = () => {
    return (
        <div>
            <Navbar />
            <Outlet /> 
        </div>
    );
};
export default Content;