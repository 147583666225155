import React from 'react';

const ApplicationStateContext = React.createContext({
    loading: false,
    setLoading: (loading: boolean) => { },
});

interface Props {
   children?: React.ReactNode;
}

const ApplicationStateProvider = ({ children }: Props) => {
    const [loading, setLoading] = React.useState(false);

    return (
        <ApplicationStateContext.Provider value={{ loading, setLoading }}>
            {children}
        </ApplicationStateContext.Provider>
    );
}
export { ApplicationStateContext, ApplicationStateProvider };
